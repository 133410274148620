import useUpdateSubscription from '@api/useUpdateSubscription';
import Modal from '@components/Modal';
import ServerErrorMessage from '@components/ServerErrorMessage';
import Translate from '@components/Translate';
import { useCreateToast } from '@context/ToastContext';
import useTranslation from '@helpers/useTranslation';
import React, { FC } from 'react';
import { Box, Button, Form } from 'react-bulma-components';

interface DashboardSubscriptionViewResumePlanModalProps {
  closeModal(): void;
  plan: string;
}

const DashboardSubscriptionViewResumePlanModal: FC<DashboardSubscriptionViewResumePlanModalProps> =
  ({ closeModal, plan }) => {
    const { t } = useTranslation('subscription');

    const createToast = useCreateToast();

    const {
      isError: hasUpdateSubscriptionError,
      isLoading: isUpdatingSubscription,
      mutate: updateSubscription,
    } = useUpdateSubscription();

    const handleConfirmClick = () => {
      updateSubscription(
        { plan: 'resume-plan' },
        {
          onSuccess: () => {
            createToast(t('subscriptionResumeSuccess'), 'success');

            closeModal();
          },
        }
      );
    };

    return (
      <Modal closeModal={closeModal} contentLabel={t('switchPlansModal')}>
        <Box>
          <Form.Field>
            <p>
              <Translate
                components={{ strong: <strong /> }}
                message={t('resumePlanDescription', { plan: t(plan) })}
              />
            </p>
          </Form.Field>
          {hasUpdateSubscriptionError && <ServerErrorMessage />}
          <Form.Field kind="group">
            <Form.Control>
              <Button color="primary" loading={isUpdatingSubscription} onClick={handleConfirmClick}>
                {t('common:confirm')}
              </Button>
            </Form.Control>
            <Form.Control>
              <Button color="light" onClick={closeModal}>
                {t('common:cancel')}
              </Button>
            </Form.Control>
          </Form.Field>
        </Box>
      </Modal>
    );
  };

export default DashboardSubscriptionViewResumePlanModal;
