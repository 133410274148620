import PageMeta from '@components/PageMeta';
import useTranslation from '@helpers/useTranslation';
import DashboardTemplate from '@templates/DashboardTemplate';
import DashboardSubscriptionView from '@views/DashboardSubscriptionView';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

const DashboardSubscriptionPage: FC<PageProps> = () => {
  const { t } = useTranslation('pages');

  return (
    <DashboardTemplate>
      <PageMeta title={t('subscription')} />
      <DashboardSubscriptionView />
    </DashboardTemplate>
  );
};

export default DashboardSubscriptionPage;
