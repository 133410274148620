import Translate from '@components/Translate';
import useModal from '@helpers/useModal';
import useTranslation from '@helpers/useTranslation';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { Box, Button, Columns, Heading, Tag } from 'react-bulma-components';
import DashboardSubscriptionViewResumePlanModal from './DashboardSubscriptionViewResumePlanModal';
import DashboardSubscriptionViewSwitchPlansModal from './DashboardSubscriptionViewSwitchPlansModal';
import DashboardSubscriptionViewUpdateCardModal from './DashboardSubscriptionViewUpdateCardModal';

interface DashboardPremiumViewProps {
  cardBrand: string | null;
  cardLastFour: string | null;
  gracePeriod: string | null;
  plan: string;
}

const CARD_BRANDS: Record<string, string> = {
  amex: 'American Express',
  diners: 'Diners Club',
  discover: 'Discover',
  jcb: 'JCB',
  mastercard: 'MasterCard',
  unionpay: 'UnionPay',
  unknown: 'Unknown',
  visa: 'Visa',
};

const DashboardSubscriptionViewPremiumUser: FC<DashboardPremiumViewProps> = ({
  cardBrand,
  cardLastFour,
  gracePeriod,
  plan,
}) => {
  const { t } = useTranslation(['subscription', 'validation']);

  const {
    closeModal: closeSwitchPlansModal,
    isOpen: isSwitchPlansModalOpen,
    openModal: openSwitchPlansModal,
  } = useModal();

  const {
    closeModal: closeResumePlanModal,
    isOpen: isResumePlanModalOpen,
    openModal: openResumePlanModal,
  } = useModal();

  const {
    closeModal: closeUpdateCardModal,
    isOpen: isUpdateCardModalOpen,
    openModal: openUpdateCardModal,
  } = useModal();

  return (
    <>
      <Box>
        <Heading>{t('currentPlan')}</Heading>
        <Columns vCentered>
          <Columns.Column size={0}>
            <p>
              <Translate
                components={{ strong: <strong /> }}
                message={t('yourCurrentPlan', { plan: t(plan) })}
              />
            </p>
            {gracePeriod && (
              <Tag color="danger">
                {t('canceledDescription', {
                  endDate: format(new Date(gracePeriod), 'MMM d, yyyy'),
                })}
              </Tag>
            )}
          </Columns.Column>
          <Columns.Column narrow>
            {gracePeriod ? (
              <Button color="light" onClick={openResumePlanModal}>
                {t('resumePlan')}
              </Button>
            ) : (
              <Button color="light" onClick={openSwitchPlansModal}>
                {t('switchPlans')}
              </Button>
            )}
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <Heading>{t('paymentMethod')}</Heading>
        <Columns vCentered>
          {cardBrand && cardLastFour && (
            <Columns.Column size={0}>
              {CARD_BRANDS[cardBrand]} •••• {cardLastFour}
            </Columns.Column>
          )}
          <Columns.Column narrow>
            <Button color="light" onClick={openUpdateCardModal}>
              {t('updateCard')}
            </Button>
          </Columns.Column>
        </Columns>
      </Box>
      {isSwitchPlansModalOpen && (
        <DashboardSubscriptionViewSwitchPlansModal
          closeModal={closeSwitchPlansModal}
          currentPlan={plan}
        />
      )}
      {isResumePlanModalOpen && (
        <DashboardSubscriptionViewResumePlanModal closeModal={closeResumePlanModal} plan={plan} />
      )}
      {isUpdateCardModalOpen && (
        <DashboardSubscriptionViewUpdateCardModal closeModal={closeUpdateCardModal} />
      )}
    </>
  );
};

export default DashboardSubscriptionViewPremiumUser;
