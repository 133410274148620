import useUpdateSubscription from '@api/useUpdateSubscription';
import Modal from '@components/Modal';
import SelectField from '@components/SelectField';
import ServerErrorMessage from '@components/ServerErrorMessage';
import Translate from '@components/Translate';
import { useCreateToast } from '@context/ToastContext';
import useTranslation from '@helpers/useTranslation';
import React, { FC } from 'react';
import { Box, Button, Form, Message } from 'react-bulma-components';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import plans from './plans.json';

interface FormValues {
  plan: string;
}

interface DashboardSubscriptionViewSwitchPlansModalProps {
  closeModal(): void;
  currentPlan: string;
}

const DashboardSubscriptionViewSwitchPlansModal: FC<DashboardSubscriptionViewSwitchPlansModalProps> =
  ({ closeModal, currentPlan }) => {
    const { t } = useTranslation('subscription');

    const createToast = useCreateToast();

    const {
      isError: hasUpdateSubscriptionError,
      isLoading: isUpdatingSubscription,
      mutate: updateSubscription,
    } = useUpdateSubscription();

    const formProps = useForm<FormValues>();

    const handleFormSubmit: SubmitHandler<FormValues> = ({ plan }) => {
      updateSubscription(
        { plan },
        {
          onSuccess: () => {
            createToast(t('subscriptionUpdateSuccess'), 'success');

            closeModal();
          },
        }
      );
    };

    const selectedPlan = formProps.watch('plan');

    return (
      <Modal closeModal={closeModal} contentLabel={t('switchPlansModal')}>
        <Box>
          <FormProvider {...formProps}>
            <form onSubmit={formProps.handleSubmit(handleFormSubmit)}>
              <SelectField id="plan" label={t('plan')} name="plan">
                {plans.map(plan => (
                  <option disabled={currentPlan === plan.value} value={plan.value}>
                    {plan.label}
                  </option>
                ))}
                <option value="free">{t('free')}</option>
              </SelectField>
              <Message color={selectedPlan === 'free' ? 'danger' : 'info'}>
                <Message.Body>
                  <Translate
                    components={{ strong: <strong /> }}
                    message={
                      selectedPlan === 'free'
                        ? t('cancelPlanDescription')
                        : t('switchPlanDescription')
                    }
                  />
                </Message.Body>
              </Message>
              {hasUpdateSubscriptionError && <ServerErrorMessage />}
              <Form.Field kind="group">
                <Form.Control>
                  <Button color="primary" loading={isUpdatingSubscription}>
                    {t('common:confirm')}
                  </Button>
                </Form.Control>
                <Form.Control>
                  <Button color="light" onClick={closeModal}>
                    {t('common:cancel')}
                  </Button>
                </Form.Control>
              </Form.Field>
            </form>
          </FormProvider>
        </Box>
      </Modal>
    );
  };

export default DashboardSubscriptionViewSwitchPlansModal;
