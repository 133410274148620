import useFetchApi from '@helpers/useFetchApi';
import { useMutation, UseMutationResult, useQueryClient } from 'react-query';

interface UpdateSubscriptionResponse {
  cardBrand: string | null;
  cardLastFour: string | null;
  gracePeriod: string | null;
  hasCanceled: boolean;
  plan: string;
}

interface Subscription {
  paymentMethodId?: string;
  plan?: string;
}

export default function useUpdateSubscription(): UseMutationResult<
  UpdateSubscriptionResponse,
  unknown,
  Subscription
> {
  const fetchApi = useFetchApi();

  const queryClient = useQueryClient();

  return useMutation<UpdateSubscriptionResponse, unknown, Subscription>(
    subscription => fetchApi.put('/api/subscription/', subscription),
    {
      onSuccess: newSubscription => {
        queryClient.setQueryData('subscription', newSubscription);
      },
    }
  );
}
