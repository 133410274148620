import useFetchSubscriptionToken from '@api/useFetchSubscriptionToken';
import useTranslation from '@helpers/useTranslation';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent, StripeCardElementOptions } from '@stripe/stripe-js';
import cc from 'classcat';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Form } from 'react-bulma-components';
import { useFormContext } from 'react-hook-form';
import * as styles from './DashboardSubscriptionViewCreditCardField.module.scss';

interface CardElementState {
  complete: boolean;
  error: string | null;
}

export interface DashboardSubscriptionViewCreditCardFieldRef {
  getPaymentMethodId(name: string): Promise<string>;
}

const DashboardSubscriptionViewCreditCardField =
  forwardRef<DashboardSubscriptionViewCreditCardFieldRef>((_props, ref) => {
    const { t } = useTranslation(['subscription', 'validation']);

    const stripe = useStripe();
    const elements = useElements();

    // Store a successful paymentMethodId if it gets called multiple times (ie. in
    // the event of a server failure). Otherwise calling .confirmCardSetup with an
    // already confirmed card will result in a processing error.
    const paymentMethodId = useRef<string | null>(null);

    const { data: subscriptionToken } = useFetchSubscriptionToken();

    const { clearErrors, formState, register, setError } = useFormContext();

    const [cardElementState, setCardElementState] = useState<CardElementState>({
      complete: false,
      error: null,
    });

    useImperativeHandle(
      ref,
      () => ({
        getPaymentMethodId: async name => {
          try {
            if (paymentMethodId.current) {
              return paymentMethodId.current;
            }

            if (!stripe || !elements) {
              throw new Error('"stripe" or "elements" is not yet defined.');
            }

            const cardElement = elements.getElement(CardElement);

            if (!cardElement) {
              throw new Error('Unable to find Card Element.');
            } else if (!subscriptionToken) {
              throw new Error('Subscription token is not defined.');
            }

            const setupIntent = await stripe.confirmCardSetup(subscriptionToken.token, {
              payment_method: {
                billing_details: { name },
                card: cardElement,
              },
            });

            if (setupIntent.error || !setupIntent.setupIntent.payment_method) {
              const message = setupIntent.error?.message ?? t('validation:paymentError');

              throw new Error(message);
            }

            paymentMethodId.current = setupIntent.setupIntent.payment_method;

            return paymentMethodId.current;
          } catch (error) {
            setError('stripe', { message: error.message });

            throw new Error(error);
          }
        },
      }),
      [elements, setError, stripe, subscriptionToken, t]
    );

    const handleCardElementChange = (event: StripeCardElementChangeEvent) => {
      clearErrors('stripe');

      setCardElementState({
        complete: event.complete,
        error: event.error?.message ?? null,
      });
    };

    const cardElementStyles: StripeCardElementOptions['style'] = {
      base: {
        '::placeholder': { color: '#a9a9a9' },
        color: '#4a4a4a',
        fontFamily: `'BlinkMacSystemFont', '-apple-system', '"Segoe UI"', '"Roboto"', '"Oxygen"', '"Ubuntu"', '"Cantarell"', '"Fira Sans"', '"Droid Sans"', '"Helvetica Neue"', '"Helvetica"', '"Arial"', 'sans-serif'`,
        fontSize: '16px',
        fontWeight: '400',
      },
      invalid: {
        color: '#f14668',
      },
    };

    const cardElementError = formState.errors.stripe?.message;

    return (
      <Form.Field>
        <Form.Label>{t('creditCardInformation')}</Form.Label>
        {subscriptionToken?.token ? (
          <CardElement
            onChange={handleCardElementChange}
            options={{
              classes: {
                base: cc({
                  [styles.cardElement]: true,
                  [styles.hasError]: !!cardElementError,
                }),
                focus: styles.hasFocus,
              },
              style: cardElementStyles,
            }}
          />
        ) : (
          <Form.Control loading>
            <Form.Input readOnly placeholder={t('common:loading')} />
          </Form.Control>
        )}
        {cardElementError && <Form.Help color="danger">{cardElementError}</Form.Help>}
        <input
          type="hidden"
          {...register('stripe', {
            validate: () => {
              return cardElementState.error
                ? cardElementState.error
                : !cardElementState.complete
                ? t('validation:genericRequired')
                : true;
            },
          })}
        />
      </Form.Field>
    );
  });

export default DashboardSubscriptionViewCreditCardField;
