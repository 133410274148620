import useUpdateSubscription from '@api/useUpdateSubscription';
import Modal from '@components/Modal';
import ServerErrorMessage from '@components/ServerErrorMessage';
import TextField from '@components/TextField';
import { useCreateToast } from '@context/ToastContext';
import useTranslation from '@helpers/useTranslation';
import React, { FC, useRef, useState } from 'react';
import { Box, Button, Form } from 'react-bulma-components';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import DashboardSubscriptionViewCreditCardField, {
  DashboardSubscriptionViewCreditCardFieldRef,
} from './DashboardSubscriptionViewCreditCardField';

interface FormValues {
  name: string;
  stripe: string;
}

interface DashboardSubscriptionViewUpdateCardModalProps {
  closeModal(): void;
}

const DashboardSubscriptionViewUpdateCardModal: FC<DashboardSubscriptionViewUpdateCardModalProps> =
  ({ closeModal }) => {
    const { t } = useTranslation(['subscription', 'validation']);

    const createToast = useCreateToast();

    const formProps = useForm<FormValues>();

    const { isError: hasUpdateSubscriptionError, mutate: updateSubscription } =
      useUpdateSubscription();

    const creditCardFieldRef = useRef<DashboardSubscriptionViewCreditCardFieldRef>(null);

    const [isLoading, setIsLoading] = useState(false);

    const handleFormSubmit: SubmitHandler<FormValues> = async ({ name }) => {
      if (!creditCardFieldRef.current) {
        return console.error('Credit card ref has not been initialized.');
      }

      setIsLoading(true);

      creditCardFieldRef.current
        .getPaymentMethodId(name)
        .then(paymentMethodId => {
          updateSubscription(
            { paymentMethodId },
            {
              onSuccess: () => {
                createToast(t('creditCardUpdateSuccess'), 'success');

                closeModal();
              },
              onSettled: () => {
                setIsLoading(false);
              },
            }
          );
        })
        .catch(error => {
          console.error(error);

          setIsLoading(false);
        });
    };

    return (
      <Modal closeModal={closeModal} contentLabel={t('updateCardModal')}>
        <Box>
          <FormProvider {...formProps}>
            <form onSubmit={formProps.handleSubmit(handleFormSubmit)}>
              <TextField
                id="name"
                label={t('cardHolderName')}
                name="name"
                validation={{ required: t('validation:required', { field: t('common:name') }) }}
              />
              <DashboardSubscriptionViewCreditCardField ref={creditCardFieldRef} />
              {hasUpdateSubscriptionError && <ServerErrorMessage />}
              <Form.Field kind="group">
                <Form.Control>
                  <Button color="primary" loading={isLoading}>
                    {t('common:update')}
                  </Button>
                </Form.Control>
                <Form.Control>
                  <Button color="light" onClick={closeModal}>
                    {t('common:cancel')}
                  </Button>
                </Form.Control>
              </Form.Field>
            </form>
          </FormProvider>
        </Box>
      </Modal>
    );
  };

export default DashboardSubscriptionViewUpdateCardModal;
