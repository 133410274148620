import useFetchApi from '@helpers/useFetchApi';
import { useQuery, UseQueryResult } from 'react-query';

interface FetchSubscriptionTokenResponse {
  token: string;
}

export default function useFetchSubscriptionToken(): UseQueryResult<FetchSubscriptionTokenResponse> {
  const fetchApi = useFetchApi();

  return useQuery('subscriptionToken', () => fetchApi.get('/api/subscription-token/'), {
    cacheTime: 1000 * 60 * 60, // 1 hour
    refetchOnWindowFocus: false,
    suspense: false,
  });
}
