import useCreateSubscription from '@api/useCreateSubscription';
import SelectField from '@components/SelectField';
import ServerErrorMessage from '@components/ServerErrorMessage';
import TextField from '@components/TextField';
import Translate from '@components/Translate';
import { useCreateToast } from '@context/ToastContext';
import useTranslation from '@helpers/useTranslation';
import React, { FC, useRef, useState } from 'react';
import { Box, Button, Columns, Heading } from 'react-bulma-components';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import DashboardSubscriptionViewCreditCardField, {
  DashboardSubscriptionViewCreditCardFieldRef,
} from './DashboardSubscriptionViewCreditCardField';
import plans from './plans.json';

interface FormValues {
  name: string;
  plan: string;
  stripe: string;
}

interface DashboardSubscriptionViewFreeUserProps {
  onUserSubscribe: () => void;
}

const DashboardSubscriptionViewFreeUser: FC<DashboardSubscriptionViewFreeUserProps> = ({
  onUserSubscribe,
}) => {
  const { t } = useTranslation(['subscription', 'validation']);

  const createToast = useCreateToast();

  const { isError: hasCreateSubscriptionError, mutate: createSubscription } =
    useCreateSubscription();

  const formProps = useForm<FormValues>();

  const creditCardFieldRef = useRef<DashboardSubscriptionViewCreditCardFieldRef>(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit: SubmitHandler<FormValues> = async ({ name, plan }) => {
    if (!creditCardFieldRef.current) {
      return console.error('Credit card ref has not been initialized.');
    }

    setIsLoading(true);

    creditCardFieldRef.current.getPaymentMethodId(name).then(paymentMethodId => {
      createSubscription(
        { paymentMethodId, plan },
        {
          onSuccess: () => {
            createToast(t('subscriptionCreateSuccess'), 'success');

            onUserSubscribe();
          },
          onSettled: () => {
            setIsLoading(false);
          },
        }
      );
    });
  };

  return (
    <>
      <Box>
        <Heading>{t('currentPlan')}</Heading>
        <Translate
          components={{ strong: <strong /> }}
          message={t('yourCurrentPlan', { plan: t('free') })}
        />
      </Box>
      <Box>
        <Heading>{t('getPremium')}</Heading>
        <p>{t('getPremiumDescription')}</p>
        <Columns>
          <Columns.Column desktop={{ size: 10 }} widescreen={{ size: 8 }}>
            <FormProvider {...formProps}>
              <form onSubmit={formProps.handleSubmit(handleFormSubmit)}>
                <TextField
                  id="name"
                  label={t('cardHolderName')}
                  name="name"
                  validation={{ required: t('validation:required', { field: t('common:name') }) }}
                />
                <SelectField id="plan" label={t('plan')} name="plan">
                  {plans.map(plan => (
                    <option key={plan.value} value={plan.value}>
                      {plan.label}
                    </option>
                  ))}
                </SelectField>
                <DashboardSubscriptionViewCreditCardField ref={creditCardFieldRef} />
                {hasCreateSubscriptionError && <ServerErrorMessage />}
                <Button color="primary" loading={isLoading}>
                  {t('upgrade')}
                </Button>
              </form>
            </FormProvider>
          </Columns.Column>
        </Columns>
      </Box>
    </>
  );
};

export default DashboardSubscriptionViewFreeUser;
