import useFetchApi from '@helpers/useFetchApi';
import { useQuery } from 'react-query';

interface FetchSubscriptionResponse {
  cardBrand: string | null;
  cardLastFour: string | null;
  gracePeriod: string | null;
  hasCanceled: boolean;
  plan: string;
}

export default function useFetchSubscription(): SuspendedQueryResult<FetchSubscriptionResponse> {
  const fetchApi = useFetchApi();

  return useQuery('subscription', () => fetchApi.get('/api/subscription/'), {
    cacheTime: 1000 * 60 * 60, // 1 hour
  }) as SuspendedQueryResult<FetchSubscriptionResponse>;
}
