import React, { FC } from 'react';
import ReactModal from 'react-modal';
import * as styles from './Modal.module.scss';

interface ModalProps {
  closeModal(): void;
  contentLabel: string;
}

const Modal: FC<ModalProps> = ({ closeModal, children, contentLabel }) => {
  return (
    <ReactModal
      className="modal-content"
      contentLabel={contentLabel}
      isOpen
      onRequestClose={closeModal}
      overlayClassName={styles.overlay}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
