import useFetchSubscription from '@api/useFetchSubscription';
import Translate from '@components/Translate';
import useTranslation from '@helpers/useTranslation';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { FC, useState } from 'react';
import { Content, Message } from 'react-bulma-components';
import DashboardSubscriptionViewFreeUser from './DashboardSubscriptionViewFreeUser';
import DashboardSubscriptionViewPremiumUser from './DashboardSubscriptionViewPremiumUser';

const stripe = loadStripe(`${process.env.GATSBY_STRIPE_API_KEY}`);

const DashboardSubscriptionView: FC = () => {
  const { t } = useTranslation(['subscription']);

  const { data: subscription } = useFetchSubscription();

  const [isThankYouMessageVisible, setIsThankYouMessageVisible] = useState(false);

  const handleUserSubscribe = () => {
    setIsThankYouMessageVisible(true);
  };

  return (
    <Elements stripe={stripe}>
      <Content>
        {isThankYouMessageVisible && (
          <Message color="success">
            <Message.Body>
              <p>{t('thankYouHeader')}</p>
              <Translate
                components={{
                  supportLink: <a href="https://trello.com/b/pPFiIODT/" target="pomello-board" />, // eslint-disable-line jsx-a11y/anchor-has-content
                }}
                message={t('thankYouMessage')}
              />
            </Message.Body>
          </Message>
        )}
        {subscription.plan === 'free' ? (
          <DashboardSubscriptionViewFreeUser onUserSubscribe={handleUserSubscribe} />
        ) : (
          <DashboardSubscriptionViewPremiumUser
            cardBrand={subscription.cardBrand}
            cardLastFour={subscription.cardLastFour}
            gracePeriod={subscription.gracePeriod}
            plan={subscription.plan}
          />
        )}
      </Content>
    </Elements>
  );
};

export default DashboardSubscriptionView;
