import { useCallback } from 'react';
import { useState } from 'react';

interface UseModal {
  closeModal(): void;
  isOpen: boolean;
  openModal(): void;
}

export default function useModal(): UseModal {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    closeModal,
    isOpen,
    openModal,
  };
}
